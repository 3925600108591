<template>
    <div class="error-page">
    <div class="content-con">
      <img :src="error404" alt="404">
      <div class="text-con">
        <h4>404</h4>
        <h5>您所寻找的页面不存在。</h5>
        <h5 style="top: 110px;"> 你可以点击下面的按钮，返回主页。</h5>
      </div>
      <el-button type="primary" plain style="position:absolute;right: 20px;bottom:10px;"  @click="$router.push('/')" >返回首页</el-button>
    </div>
  </div>
</template>
<script>
import error404 from '@/assets/error-page/error-404.svg'
export default {
    setup() {
        return{
            error404
        }
    },
}
</script>
<style scoped>
.error-page {
    width: 100%;
    height: 100%;
    position: relative;
    background: #f8f8f9;
}
.error-page .content-con {
    width: 700px;
    height: 600px;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%,-60%);
    transform: translate(-50%,-60%);
}
.error-page .content-con img {
    display: block;
    width: 100%;
    height: 100%;
}
.error-page .content-con .text-con h4 {
    margin: 0;
    position: absolute;
    left: 0;
    top: 0;
    font-size: 80px;
    font-weight: 700;
    color: #348eed;
}
.error-page .content-con .text-con h5 {
    position: absolute;
    width: 700px;
    left: 0;
    top: 80px;
    font-size: 20px;
    font-weight: 700;
    color: #67647d;
}
</style>